@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use 'src/css/variables/border-radius' as *
@use 'src/css/variables/media' as media
@use 'src/css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use '~booksprout-app/src/css/mixins/paragraph' as *
@use 'src/css/mixins/button' as *

.bs-w-author-plan
  border: rem-fn(1) solid get-color(bs-o)
  border-radius: $border-radius-base
  display: flex
  flex-flow: column
  padding: rem-fn(25)
  position: relative
  row-gap: rem-fn(20)

  h2, p
    margin-block-end: 0

  h2
    max-inline-size: rem-fn(134)

    &.bs-w-a-p-narrow-title
      max-inline-size: rem-fn(90)

  p
    margin-block-start: rem-fn(-5) // reduce gap to 15px

  .bs-w-button
    margin-block-end: rem-fn(5)

    @include media-query.from(media.$md-min)
      inline-size: 100%
      padding-inline: rem-fn(18)

  &__best-value
    background-color: get-color(bs-glght)

    .bs-w-a-p-plan-details
      background-color: get-color(bs-glght)

  &__publisher
    .bs-w-button
      background-color: get-color(bs-nbm)

      &:hover,
      &:focus
        background-color: transparent
        border-color: get-color(bs-nbm)

    svg
      color: get-color(bs-nbm)

  @include media-query.from(media.$md-min)
    &:not(.bs-w-author-plan__best-value)
      border-color: transparent
      border-inline-end-color: get-color(bs-o)
      border-radius: 0
      margin-inline-start: rem-fn(-1) // cover transparent border

    &:last-of-type
      border-inline-end-color: transparent

    h2
      @include paragraph('custom', 18, 25)

.bs-w-excluded
  color: get-color(bs-gl)

.bs-w-included
  color: get-color(bs-g)

.bs-w-a-p-b-v-tag
  @include paragraph('custom', 10, 12)
  align-items: center
  align-self: flex-start
  background-color: get-color(bs-g)
  border-start-start-radius: $border-radius-base
  border-end-end-radius: $border-radius-base
  color: get-color(bs-w)
  column-gap: rem-fn(10)
  display: flex
  margin-block-start: rem-fn(-26) // 25px padding + 1px border to cover parent
  margin-inline-start: rem-fn(-26) // 25px padding + 1px border to cover parent
  padding: rem-fn(15)

  @include media-query.from(media.$md-min)
    border-start-end-radius: $border-radius-base
    border-start-start-radius: 0
    border-end-end-radius: 0
    border-end-start-radius: $border-radius-base
    margin: 0
    max-inline-size: rem-fn(61)
    position: absolute
    top: 0
    right: 0

    svg
      position: absolute
      right: rem-fn(12)
      top: rem-fn(16)

.bs-w-a-p-plan-details
  background-color: get-color(bs-w)
  border-block-start: rem-fn(1) solid get-color(bs-o)
  border-end-end-radius: $border-radius-base
  border-end-start-radius: $border-radius-base
  list-style-type: none
  margin-block-end: rem-fn(-25)
  margin-block-start: 0
  margin-inline: rem-fn(-25)
  padding: 0

  ul
    display: flex
    flex-flow: row wrap
    list-style-type: none
    padding-block: 0 rem-fn(25)
    padding-inline: rem-fn(25)

    li
      color: get-color(bs-b)
      display: inline-flex
      flex-flow: column
      flex-basis: 100%
      row-gap: rem-fn(5)

      &:not(:nth-last-of-type(-n+2))
        border-block-end: rem-fn(1) solid get-color(bs-lo)
        padding-block-end: rem-fn(20)
        margin-block-end: rem-fn(20)

      @include media-query.from(media.$lg-min)
        margin-block-end: rem-fn(0)
        padding-block-start: rem-fn(12)
        text-align: center

        &.bs-w-a-p-p-d-long-text
          padding-block: rem-fn(23)

        &:not(:last-of-type)
          border-block-end: rem-fn(1) solid get-color(bs-lo)
          margin-block-end: rem-fn(0)

          &:not(.bs-w-a-p-p-d-long-text)
            padding-block: rem-fn(12)

      span
        @include paragraph('small')
        flex: 1

      svg
        margin-block-start: rem-fn(5)

        @include media-query.from(media.$lg-min)
          margin-block-start: 0
          margin-inline: auto

      &.bs-w-a-p-p-d-split
        flex-basis: 50%
        justify-content: space-between
        padding-inline-end: rem-fn(6)

        @include media-query.from(media.$lg-min)
          flex-basis: 100%
          padding-inline-end: 0

    @include media-query.from(media.$lg-min)
      padding-block-end: rem-fn(20)
      padding-inline: 0

  @include media-query.from(media.$md-min)
    border-block-start: 0

.bs-w-a-p-plan-details-btn
  @include button
  @include paragraph('medium')
  align-items: center
  background-color: transparent
  border: 0
  cursor: pointer
  display: flex
  justify-content: space-between
  inline-size: 100%
  padding-block: rem-fn(20)
  padding-inline: rem-fn(25)

  svg
    color: get-color(bs-g)

  &[aria-expanded="true"]
    svg
      transform: rotate(180deg)

.bs-w-prices
  display: flex
  flex-flow: column
  row-gap: rem-fn(20)

  @include media-query.from(media.$sm-min)
    margin-block-start: auto

.bs-w-original-price
  @include paragraph('medium')
  color: get-color(bs-gm)

.bs-w-price
  @include paragraph('medium')
  color: get-color(bs-b)
  display: flex
  flex-flow: column

  strong
    @include paragraph('custom', 32, 44)
