@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import "../css/functions/rem-fn"
@import "../css/mixins/paragraph"

.bs-offer-countdown
  display: flex
  column-gap: rem-fn(15)

.bs-o-c-time
  align-items: center
  display: flex
  flex-flow: column
  row-gap: rem-fn(5)

  strong
    @include paragraph('custom', 10, 14)
    text-transform: uppercase

.bs-o-c-separator
  block-size: rem-fn(4)
  border: rem-fn(2) solid $bs-green
  border-radius: 50%
  inline-size: rem-fn(4)
  margin-block-start: rem-fn(20) // don't use align-items as the dot will be pushed too far

.bs-o-c-t-clock
  color: #000
  font-weight: bold
  display: flex

  span
    @include paragraph('custom', 32, 44)
