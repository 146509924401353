@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '../css/functions/get-color' as *

.bs-w-member
  align-items: center
  display: flex
  column-gap: rem-fn(20)
  margin: 0

  figcaption
    display: flex
    flex-direction: column
    position: relative
    text-align: left

    strong
      color: get-color(bs-b)
      font-weight: 600

    span:not(.bs-w-tag)
      color: get-color(bs-gl)
      font-weight: 700

    .bs-w-tag
      margin-block-start: rem-fn(15)
      position: absolute
      top: 100%
      white-space: nowrap
