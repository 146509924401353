@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '../css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use '~booksprout-app/src/css/mixins/paragraph' as *
@use '../css/mixins/button' as *
@use '../css/variables/border-radius' as *
@use '../css/variables/box-shadow' as *
@use '../css/variables/media'

.bs-w-button
  @include button
  @include paragraph('custom', 15, 21)
  align-items: center
  background-color: get-color(bs-g)
  border: rem-fn(1) solid transparent
  border-radius: $border-radius-base
  box-shadow: $box-shadow-base
  color: get-color(bs-w)
  display: flex
  font-weight: 700
  justify-content: center
  inline-size: 100%
  padding-block: rem-fn(15)
  transition: background-color .1s linear

  &:hover,
  &:focus
    background-color: get-color(bs-w)
    border-color: get-color(bs-g)
    color: get-color(bs-b)
    text-decoration: none

  @include media-query.from(media.$md-min)
    block-size: max-content
    inline-size: max-content
    padding-inline: rem-fn(30)
