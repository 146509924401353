//
//	@usage: Pass a media screen size to get a media query
//	@param: {String} $size
//

@use '../functions/rem-fn' as *

@mixin from($size)
  @media screen and (min-width: rem-fn($size))
    @content
