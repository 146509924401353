@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import 'booksprout-app/src/css/mixins/media'
@import 'booksprout-app/src/css/mixins/paragraph'

.bs-offer
  border: rem-fn(1) solid $bs-light-outline
  border-radius: $bs-border-radius-sm
  color: #000
  display: flex
  flex-flow: column
  padding: rem-fn(30)
  row-gap: rem-fn(20)

  > p
    @include paragraph('custom', 14, 22)
    margin: 0

  @include from($breakpoint-md-min)
    flex-flow: row
    justify-content: space-between
    inline-size: 100%
    margin-inline: auto
    max-inline-size: rem-fn(970)

    p
      max-inline-size: rem-fn(461)
