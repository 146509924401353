@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use 'src/css/variables/border-radius' as *
@use 'src/css/variables/box-shadow' as *
@use 'src/css/variables/media' as media
@use '~booksprout-app/src/css/mixins/media' as media-query
@use '~booksprout-app/src/css/mixins/paragraph' as *
@use 'src/css/functions/get-color' as *

.bs-w-plans
  h1
    margin-block-end: rem-fn(20)
    max-inline-size: rem-fn(237)

    + p
      margin-block-end: rem-fn(40)

  article
    &:first-child
      .bs-w-article-content
        padding-block-start: rem-fn(30)

        @include media-query.from(media.$md-min)
          > h1, > p
            text-align: center

          > h1
            max-inline-size: 100%

    &:nth-child(even),
    &.bs-w-p-faqs
      background-color: $bs-light-grey-background
      position: relative

.bs-w-p-intro
  .bs-offer
    margin-block-end: rem-fn(30)

    @include media-query.from(media.$md-min)
      margin-block-end: rem-fn(80)

  .bs-w-author-plan
    margin-block-end: rem-fn(30)

    @include media-query.from(media.$md-min)
      margin-block-end: 0

  @include media-query.from(media.$md-min)
    .bs-w-article-content
      display: grid
      column-gap: rem-fn(50)
      grid-template-columns: repeat(3, 1fr)

      h1,
      > p,
      .bs-offer,
      .bs-billing-cycle-switch,
      .bs-w-author-plans,
      .bs-w-free-plans
        grid-column: span 3

      > p
        margin-block-end: rem-fn(70)

.bs-w-tag-group
  column-gap: rem-fn(10)
  display: flex

.bs-w-author-plans
  margin-block-end: rem-fn(80)

  @include media-query.from(media.$sm-min)
    column-gap: rem-fn(10)
    display: grid
    grid-template-columns: repeat(2, 1fr)

  @include media-query.from(media.$md-min)
    column-gap: 0
    grid-template-columns: repeat(4, 1fr)
    margin-block-end: rem-fn(120)

  @include media-query.from(media.$lg-min)
    grid-template-columns: rem-fn(320) repeat(4, 1fr)

.bs-w-a-p-testimonial-and-features
  display: flex
  flex-flow: column
  justify-content: space-between

.bs-w-a-p-testimonial
  border: rem-fn(1) solid get-color(bs-lo)
  border-radius: $border-radius-base
  box-shadow: $box-shadow-base
  display: flex
  flex-flow: column
  margin: 0
  max-inline-size: rem-fn(270)
  padding: rem-fn(30)
  position: relative
  row-gap: rem-fn(20)

  &:after
    block-size: 0
    content: ''
    border-block-start: rem-fn(8) solid transparent
    border-block-end: rem-fn(8) solid transparent
    border-inline-start: rem-fn(8) solid get-color(bs-w)
    filter: drop-shadow(rem-fn(1) 0 0 get-color(bs-lo)) // creates border around triangle
    inline-size: 0
    position: absolute
    right: rem-fn(-8)
    top: rem-fn(42)

.bs-w-a-p-features
  @include media-query.from(media.$md-min)
    align-self: flex-end
    list-style: none
    margin: 0
    padding: 0
    padding-block-end: rem-fn(9)

    li
      align-items: center
      column-gap: rem-fn(10)
      display: flex
      padding-block: rem-fn(12)
      padding-inline-end: rem-fn(12)

      .bs-tooltip
        align-self: flex-start
        color: get-color(bs-g)
        margin-block-start: rem-fn(4) // to align vertically with text

        .q-btn__wrapper
          min-block-size: auto
          padding: 0

      &:not(:last-of-type)
        border-block-end: rem-fn(1) solid get-color(bs-lo)

.bs-w-free-plans
  h2
    @include paragraph('custom', 24, 33)
    margin-block-end: rem-fn(40)
    max-inline-size: rem-fn(286)

    @include media-query.from(media.$md-min)
      @include paragraph('custom', 52, 71)
      margin-block-end: rem-fn(60)
      max-inline-size: 100%
      text-align: center

.bs-w-f-p-plans
  column-gap: rem-fn(30)
  display: flex
  flex-flow: column
  row-gap: rem-fn(20)

  @include media-query.from(media.$md-min)
    flex-flow: row

.bs-w-f-p-plan
  border: rem-fn(1) solid get-color(bs-lo)
  border-radius: $border-radius-base
  display: flex
  flex-flow: column
  margin: 0
  padding: rem-fn(40)
  row-gap: rem-fn(20)

  svg
    color: get-color(bs-g)

  figcaption
    block-size: 100%
    display: flex
    flex-flow: column nowrap
    row-gap: rem-fn(20)

    strong
      @include paragraph('custom', 18, 25)
      color: get-color(bs-b)

    p
      @include paragraph('medium')
      margin-block-end: rem-fn(12)

    .bs-w-button
      margin-block-start: auto

  @include media-query.from(media.$md-min)
    flex-basis: 50%

article.bs-w-p-join
  h2
    margin-block-end: rem-fn(40)

  p
    color: get-color(bs-b)
    font-size: rem-fn(20)
    line-height: line-height(27, 20)
    margin-block-end: rem-fn(50)

  .bs-w-member
    margin-inline: rem-fn(25)

  @include media-query.from(media.$md-min)
    .bs-w-article-content
      display: grid
      grid-template-columns: 1fr rem-fn(910) 1fr
      max-inline-size: rem-fn(media.$wide-content-max-width)
      text-align: center

      h2, p, .bs-w-member
        grid-column: 2
        margin-inline: auto

      h2
        inline-size: rem-fn(714)
        margin-block-end: rem-fn(100)

      p
        margin-block-end: rem-fn(60)

.bs-w-p-faqs
  background-color: get-color(bs-nb) !important
  color: get-color(bs-w)

  &:before
    background-color: get-color(bs-nb)

  h2
    color: get-color(bs-w)
    margin-block-end: rem-fn(40)
    max-inline-size: rem-fn(213)

  .bs-w-faq
    margin-inline: rem-fn(-30)
    padding-inline: rem-fn(30)

    &:not(:last-child)
      margin-block-end: rem-fn(30)

    &:last-child
      border-block-end: 0

    @include media-query.from(media.$md-min)
      .bs-w-article-content
        margin-inline: 0
        padding-inline: 0

        &:not(:last-child)
          margin-block-end: rem-fn(40)

  @include media-query.from(media.$md-min)
    h2
      margin-block-end: rem-fn(50)
      max-inline-size: 100%
      text-align: center
