@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import '../css/functions/rem-fn'
@import '../css/mixins/media'

.bs-billing-cycle-radios
  background-color: $bs-green
  border-radius: rem-fn(92)
  cursor: pointer
  display: flex
  flex-flow: column
  justify-content: space-between
  max-block-size: rem-fn(108)
  padding: rem-fn(4)

  input
    display: none

    &:checked + label
      background-color: #fff
      block-size: rem-fn(24)
      border-radius: 50%
      inline-size: rem-fn(24)

  @include from($breakpoint-sm-min)
    flex-flow: row
    inline-size: rem-fn(82)
    max-block-size: rem-fn(32)
