// BS Base Typography, Colors etc.

// Color Variables
$bs-navy-blue: #1A2D3F
$bs-navy-blue-filter: brightness(0) saturate(100%) invert(15%) sepia(13%) saturate(1930%) hue-rotate(168deg) brightness(93%) contrast(93%)

$bs-navy-blue-medium: #2E4F6F
$bs-navy-blue-medium-filter: brightness(0) saturate(100%) invert(27%) sepia(15%) saturate(1814%) hue-rotate(168deg) brightness(94%) contrast(88%)

$bs-light-blue: #6594C0
$bs-light-blue-filter: brightness(0) saturate(100%) invert(60%) sepia(8%) saturate(1976%) hue-rotate(168deg) brightness(91%) contrast(94%)

$bs-light-blue-medium: #42729F
$bs-light-blue-medium-filter: brightness(0) saturate(100%) invert(39%) sepia(19%) saturate(1292%) hue-rotate(168deg) brightness(101%) contrast(86%)

$bs-light-blue-really: #C5D7E7
$bs-light-blue-really-filter: brightness(0) saturate(100%) invert(92%) sepia(4%) saturate(2012%) hue-rotate(179deg) brightness(94%) contrast(93%)

$bs-light-grey: #B4B4B4
$bs-light-grey-background: #F6F8FB
$bs-light-grey-background-filter: brightness(0) saturate(100%) invert(100%) sepia(26%) saturate(517%) hue-rotate(176deg) brightness(100%) contrast(98%)

$bs-light-grey-outline: #E5E5E5
$bs-light-outline: #E8E8E8

$bs-light-grey-medium: #D8F4FF
$bs-light-grey-medium-filter: brightness(0) saturate(100%) invert(91%) sepia(3%) saturate(2677%) hue-rotate(177deg) brightness(102%) contrast(105%)

$bs-medium-grey: #535353

$bs-red: #E1653F
$bs-red-filter: brightness(0) saturate(100%) invert(49%) sepia(41%) saturate(929%) hue-rotate(327deg) brightness(92%) contrast(91%)

$bs-red-light: #FFE0D8
$bs-red-light-filter: brightness(0) saturate(100%) invert(99%) sepia(42%) saturate(1460%) hue-rotate(293deg) brightness(98%) contrast(110%)

$bs-red-dark: #873E2B
$bs-red-dark-filter: brightness(0) saturate(100%) invert(30%) sepia(29%) saturate(1291%) hue-rotate(326deg) brightness(87%) contrast(89%)

$bs-magenta: #F86D72
$bs-magenta-filter: brightness(0) saturate(100%) invert(55%) sepia(89%) saturate(1648%) hue-rotate(316deg) brightness(100%) contrast(95%)

$bs-green: #00A61A
$bs-green-filter: brightness(0) saturate(100%) invert(37%) sepia(97%) saturate(909%) hue-rotate(95deg) brightness(98%) contrast(103%)

$bs-green-light: #d8ffde
$bs-green-light-filter: brightness(0) saturate(100%) invert(94%) sepia(14%) saturate(574%) hue-rotate(63deg) brightness(104%) contrast(104%)

$bs-green-dark: #00620F
$bs-green-dark-filter: brightness(0) saturate(100%) invert(16%) sepia(91%) saturate(2859%) hue-rotate(134deg) brightness(90%) contrast(104%)

$bs-green-opaque: #82d38f
$bs-green-opaque-filter: brightness(0) saturate(100%) invert(75%) sepia(20%) saturate(702%) hue-rotate(78deg) brightness(97%) contrast(91%)

$bs-green-solid: #33C84A

$bs-mustard-yellow: #FF9700
$bs-mustard-yellow-filter: brightness(0) saturate(100%) invert(50%) sepia(100%) saturate(483%) hue-rotate(357deg) brightness(103%) contrast(108%)

$bs-mustard-yellow-light: #FFFAD8
$bs-mustard-yellow-light-filter: brightness(0) saturate(100%) invert(96%) sepia(5%) saturate(2020%) hue-rotate(319deg) brightness(104%) contrast(110%)

$bs-mustard-yellow-dark: #6A5C01
$bs-mustard-yellow-dark-filter: brightness(0) saturate(100%) invert(32%) sepia(62%) saturate(685%) hue-rotate(16deg) brightness(95%) contrast(99%)

$bs-facebook-blue: #3B5998
$bs-amazon-orange: #FF9900
$bs-twitter-blue: #1ca1f2
$bs-pinterest-red: #e60022
$bs-x-black: #000000
$bs-read-only: #F0F0F0
$bs-input-bg: #E9F0F6
$bs-category-tag-explicit: #D3D3D3
$bs-category-tag: #D8FFF2

// content
$content-max-width: 1200px // padding will reduce this to 1170

// .bs-card
$card-border: .0625rem solid $bs-light-grey-outline
$card-box-shadow: .25rem .25rem .375rem rgba(0, 0, 0, 0.02)

// drop shadow
$bs-drop-shadow: #95b5d3
$bs-drop-shadow-block: .25rem .25rem .375rem rgba(0, 0, 0, 0.05)
$bs-header-drop-shadow: 0 .25rem .75rem rgba(0, 0, 0, 0.05)

// border-radius
$bs-border-radius: 3px
$bs-border-radius-sm: 6px
$bs-border-radius-md: 8px

// spacing
$bs-input-bottom-slot: 23px

// z-index'es
$bs-z-index-header: 800
$bs-z-index-menu: 1020
$bs-z-index-max: 1030
$bs-z-index-fullscreen: 1020 // needs to be same as menu in case menu is inside dialog
