@use '~booksprout-app/src/css/functions/rem-fn' as *
@use 'src/css/functions/get-color' as *

.bs-w-or-separator
  display: flex
  justify-content: center
  position: relative

  &:before
    border-block-start: rem-fn(1) solid #E0E0E0
    content: ''
    inline-size: 100%
    position: absolute
    top: 50%
    transform: translateY(50%)
    z-index: -1

  strong
    background-color: get-color(bs-w)
    inline-size: rem-fn(94)
    text-align: center

  &__vertical
    align-items: center

    &:before
      block-size: 100%
      border-block-start: 0
      border-inline-start: rem-fn(1) solid #E0E0E0
      inline-size: auto
      left: 46%
      top: 0
      transform: translateX(50%)

    strong
      block-size: rem-fn(16)
      inline-size: auto

