@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use 'src/css/functions/get-color' as *
@use 'src/css/variables/media' as media

.bs-w-faq
  border-block-end: rem-fn(1) solid #2F4457
  color: get-color(bs-w)

  &:not(:last-child)
    padding-block-end: rem-fn(30)

  h3
    color: get-color(bs-w)
    margin-block-end: rem-fn(20)

  p
    margin-block-end: 0
    white-space: pre-line

  @include media-query.from(media.$md-min)
    &:not(:last-child)
      padding-block-end: rem-fn(40)
