@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import '../css/functions/rem-fn'
@import '../css/mixins/media'
@import '../css/mixins/paragraph'

.bs-billing-cycle-switch
  column-gap: rem-fn(15)
  display: flex
  margin-block-end: rem-fn(40)

.bs-b-c-s-billing-cycles
  display: flex
  flex-flow: column
  row-gap: rem-fn(30)

  @include from($breakpoint-sm-min)
    column-gap: rem-fn(30)
    flex-flow: row
    margin-inline: auto

.bs-b-c-s-billing-cycle
  display: flex
  flex-flow: column
  inline-size: rem-fn(178)
  row-gap: rem-fn(10)

  > span:not(.bs-chip),
  > strong
    @include paragraph('medium')
    align-items: center
    display: flex
    justify-content: space-between

    @include from($breakpoint-sm-min)
      block-size: rem-fn(32)

  > strong
    color: #000

  .bs-chip
    @include paragraph('small')
    align-self: flex-start
    background-color: #D8FFDE
    border-radius: rem-fn(24)
    color: #000
    padding-block: rem-fn(8)
    padding-inline: rem-fn(14)

    &:last-child
      background-color: #FFF3A5

  @include from($breakpoint-sm-min)
    &:first-child
      > span,
      > strong
        justify-content: flex-end

    &:last-child
      > span,
      > strong
        .bs-chip
          background-color: #D8FFDE

.bs-chip-group
  column-gap: rem-fn(10)
  display: flex
