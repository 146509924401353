@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '../css/functions/get-color' as *

.bs-w-tag
  background-color: get-color(bs-gl)
  border-radius: rem-fn(20) // custom
  color: get-color(bs-w)
  font-weight: 700
  padding-block: rem-fn(6)
  padding-inline: rem-fn(16)

  &.navy-blue
    background-color: get-color(bs-nb)

  &.light-yellow
    background-color: #FEFADA
    color: get-color(bs-b)

  &.opaque-green
    background-color: get-color(bs-go)
    color: get-color(bs-b)
