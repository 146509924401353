@import '../functions/rem-fn'
@import '../functions/line-height-fn'

//
//	@usage: Pass the size to get one of the predefined paragraph sizes
//	@param: {String} $size (small, smaller, custom)
//

@mixin paragraph($size, $font-size: null, $line-height: null)
  @if $size == 'smaller'
    font-size: rem-fn(14)
    line-height: line-height(19, 14)

  @if $size == 'small'
    font-size: rem-fn(12)
    line-height: line-height(16, 12)

  @if $size == 'medium'
    font-size: rem-fn(15)
    line-height: line-height(21, 15)

  @if $size == 'custom'
    font-size: rem-fn($font-size)
    line-height: line-height($line-height, $font-size)
