@mixin button
  font-family: inherit
  font-size: 100%
  line-height: 1.15
  margin: 0
  overflow: visible
  text-transform: none

  -webkit-appearance: none

  &::-moz-focus-inner
    border-style: none
    padding: 0

  &:-moz-focusring
    outline: 1px dotted ButtonText
